<template>
  <div>
    <div class="col float-right">
      <q-btn-group outline>
        <!-- 출력 -->
        <c-btn
          label="LBL0000312"
          icon="print"
          @btnClicked="print" />
      </q-btn-group>
    </div>
    <c-tab
      type="vertical"
      :tabItems="tabItems"
      :height.sync="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.key"
          :param="param"
          :task="task"
          :height="height"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'riskOnePage',
  props: {
    param: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',
        assessmentName: '',
        ramStepCd: '',
        vendorFlag: false,
        ramTechniqueCd: '',
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      tab: 'onepage',
      task: {
        insertUrl: '',
        deleteUrl: '',
      },
      editable: true,
      assessPlan: {
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        plantCd: '',  // 사업장 코드
        plantName: '',  // 사업장명
        ramTechniqueCd: null,  // 평가기법 코드_HAZOP/KRAS/JSA/CHECKLIST
        ramAssessTypeCd: null,  // 평가구분_최초/정기/수시
        ramAssessTypeName: null,  // 평가구분_최초/정기/수시
        assessmentName: '',  // 평가명
        assessmentStartDate: '',  // 평가시작일
        assessmentEndDate: '',  // 평가종료일
        assessmentPeriod: [],
        assessmentYear: '',  // 평가년도
        ramStepCd: '',  // 평가 진행단계 코드
        assessmentManageDeptCd: '',  // 평가주관 부서 코드
        assessmentManageUserId: '',  // 평가주관 담당자 ID
        ramMatrixId: '',  // 위험메트릭스 번호
        sopMocId: '',  // MOC 일련번호
        remark: '',  // 상세내용
        regUserId: '',  // 작성자 ID
        regUserName: '',  // 작성자 ID
        regDt: '',
        reviewUserId: '',  // 검토자 ID
        reviewDate: '',  // 검토일
        approvalUserId: '',  // 승인자 ID
        approvalDate: '',  // 승인일
        chgUserId: '',  // 수정자 ID
        teams: [], // 평가팀 목록
        conferences: [], // 회의 목록
        relatedDocuments: [], // 관련자료 목록
        processes: [], // 대상공정 목록
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      if ((this.editable && !this.disabled) || this.btnEditable) {
        numHeight = numHeight - 30
      }
      return numHeight + 'px';
    },
    tabItems() {
      let oneComponent = null;
      if (this.param.ramTechniqueCd === 'RT00000001') {
        // HAZOP
        oneComponent = () => import(`${'@/pages/ram/hazop/hazopOnePageDetail.vue'}`)
      } else if (this.param.ramTechniqueCd === 'RT00000005') {
        // K-PSR
        oneComponent = () => import(`${'@/pages/ram/kpsr/kpsrOnePageDetail.vue'}`)
      } else if (this.param.ramTechniqueCd === 'RT00000010') {
        // Check-list
        oneComponent = () => import(`${'@/pages/ram/checklist/checklistOnePageDetail.vue'}`)
      } else if (this.param.ramTechniqueCd === 'RT00000015') {
        // JRA
        oneComponent = () => import(`${'@/pages/ram/jsa/jsaOnePageDetail.vue'}`)
      } else if (this.param.ramTechniqueCd === 'RT00000020') {
        // KRAS
        oneComponent = () => import(`${'@/pages/ram/kras/krasOnePageDetail.vue'}`)
      } else if (this.param.ramTechniqueCd === 'RT00000025') {
        // 4M
        oneComponent = () => import(`${'@/pages/ram/4m/4mOnePageDetail.vue'}`)
      } else if (this.param.ramTechniqueCd === 'RT00000030') {
        // CHARM
        oneComponent = () => import(`${'@/pages/ram/charm/charmOnePageDetail.vue'}`)
      } else if (this.param.ramTechniqueCd === 'RT00000035') {
        // 3단계 판단법
        oneComponent = () => import(`${'@/pages/ram/third/thirdOnePageDetail.vue'}`)
      }
      let items = [
        { key: '1', name: 'onepage', icon: 'plagiarism', label: 'LBL0001233', component: oneComponent }, // 한눈에 보기
        { key: '2', name: 'education', icon: 'edit_calendar', label: 'LBL0001234', component: () => import(`${'@/pages/ram/riskEducation.vue'}`) }, // 관련 교육
        // { key: '3', name: 'nearmiss', icon: 'local_hospital', label: '관련<br/>아차사고', component: () => import(`${'@/pages/ram/riskNearmissAccident.vue'}`) },
        // { key: '4', name: 'patrol', icon: 'directions_walk', label: '관련<br/>순회점검', component: () => import(`${'@/pages/ram/riskPatrol.vue'}`) },
        { key: '5', name: 'status', icon: 'equalizer', label: 'LBL0001235', component: () => import(`${'@/pages/ram/riskStatus.vue'}`) }, // 통계
      ]
      if (this.param.vendorFlag) {
        items.splice(1, 1)
        // items.splice(1, 1)
        // items.splice(1, 1)
      }
      return items;
    },
    printUrl() {
      let url = null;
      if (this.param.ramTechniqueCd === 'RT00000001') {
        // HAZOP
        url = selectConfig.ram.hazop.print.url;
      } else if (this.param.ramTechniqueCd === 'RT00000005') {
        // K-PSR
        url = selectConfig.ram.kpsr.print.url;
      } else if (this.param.ramTechniqueCd === 'RT00000010') {
        // Check-list
        url = selectConfig.ram.checklist.print.url;
      } else if (this.param.ramTechniqueCd === 'RT00000015') {
        // JRA
        url = selectConfig.ram.jsa.print.url;
      } else if (this.param.ramTechniqueCd === 'RT00000020') {
        // KRAS
        url = selectConfig.ram.kras.print.url;
      } else if (this.param.ramTechniqueCd === 'RT00000025') {
        // 4M
        url = selectConfig.ram.fm.print.url;
      } else if (this.param.ramTechniqueCd === 'RT00000030') {
        // CHARM
        url = selectConfig.ram.charm.print.url;
      } else if (this.param.ramTechniqueCd === 'RT00000035') {
        // 3단계 판단법
        url = selectConfig.ram.third.print.url;
      }
      return url;
    }
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.task.insertUrl = transactionConfig.ram.assessPlan.task.insert.url;
      this.task.deleteUrl = transactionConfig.ram.assessPlan.task.delete.url;
      // code setting
      // list setting
    },
    print() {
      this.$http.url = this.$format(selectConfig.ram.assessPlan.get.url, this.param.ramRiskAssessmentPlanId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.param.assessmentName = _result.data.assessmentName
      },);
      let thisVue = this;
      this.$http.url = this.$format(this.printUrl, this.param.ramRiskAssessmentPlanId);
      this.$http.type = 'GET';
      this.$http.request(
        _result => {
          // let excelName = this.param.assessmentName ? this.param.assessmentName : '';
          let preffix = ''
          switch(this.param.ramTechniqueCd){
            case 'RT00000001':
              preffix = 'HAZOP_'
              break;
            case 'RT00000005':
              preffix = 'K-PSR_'
              break;
            case 'RT00000010':
              preffix = 'CheckList_'
              break;
            case 'RT00000015':
              preffix = 'JRA_'
              break;
            case 'RT00000020':
              preffix = 'KRAS_'
              break;
            case 'RT00000025':
              preffix = '4M_'
              break;
            case 'RT00000030':
              preffix = 'CHARM_'
              break;
            case 'RT00000035':
              preffix = '3단계판단법_'
              break;
          }
          let fileOrgNm = preffix + this.param.assessmentName +'_'+ this.$comm.getToday() + '.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    },
  }
};
</script>